<template>
  <div class="special">
    <template>
      <div>
        <detail :topicData="topic" ref="qianh" />
      </div>
    </template>

    <!-- nav -->
    <van-popup
        v-model="navStatus"
        closeable
        position="left"
        :style="{ width: '8.75rem',height: '100%' }">
        <div class="nav-con">
          <ul>
            <li v-for="(item, n) in nav_list" :key="n" @click="navClick(item,n)">
              <div class="nav-list-con  p_16 " :class="n==navCount?'on':''">
                {{ n+1 }}
              </div>
            </li>
          </ul>
        </div>

    </van-popup>

    <div class="directory-nav" @click="navStatus = !navStatus">
      <p>目录</p>
    </div>

    <div class="join" @click="helpDialog = true">
      <div>联系我们</div>
      <van-icon name="arrow" />
    </div>

    <van-overlay :show="helpDialog" @click="helpDialog = false" lock-scroll>
      <div class="wrapper" @click.stop>
        <div class="helpDialog">
          <div class="helpDialog__container">
            <div class="helpDialog__content">
              <img src="https://wq-ky.com/static/img/laoshi.jpeg" />

              <div class="helpDialog__content-desc">如对题目有疑问，请长按识别并添加老师微信。感谢反馈！</div>
            </div>

            <div class="helpDialog__line" />
            <div class="helpDialog__close" @click="helpDialog = false">
              关闭
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import querystring from "querystring";
import Nav from "./nav";
import detail from "./detail";
import utils from "@/assets/js/utils";
export default {
  name: "special",
  data() {
    return {
      nav_list: [],
      navStatus: false,
      navCount: 0,
      helpDialog: false,

      topic: '',
    };
  },
  components: {
    Nav,
    detail,
  },
  watch: {
  },
  created() {
    let nav_list = this.$route.query.list;
    nav_list = nav_list.split(',');
    this.nav_list = nav_list;
    this.relate_exam(nav_list[0])
  },
  methods: {
    relate_exam(id){
      console.log('query', id);
      this.$api.queryQuestion({
        param: {
          questionID: id
        }
      }).then(res=>{
        this.topic = res;
      });
    },
    navClick(item, n){
      this.navCount = n
      this.navStatus = false
      this.relate_exam(item)
    }
  }
};
</script>

<style lang="less" scoped>
.join {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #415FD5;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 200;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.special {
  position: relative;
  // padding: 16px 0;
  padding-bottom: 100px;
  text-align: left;
  background: #f7f8fb;
  min-height: 100vh;
  .tabbar {
    position: fixed;
    bottom: 16px;
    left: 38px;
    width: 300px;
    // height: 48px;
    background: #fff;
    z-index: 9;
    margin: 0 auto;
    border-radius: 48px;
    box-sizing: border-box;

    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
    ul {
      padding: 6px 0px;
      display: flex;
      font-size: 12px;
      display: flex;
      li {
        width: 24px;
        // margin-right: 46px;
        flex-grow: 4;
        text-align: center;
        line-height: 1.2;
        // &:nth-child(4n){
        //     margin-right: 0;
        // }
        .p1 {
          font-size: 18px;
          height: 18px;
          display: inline-block;
          .van-icon {
            font-size: #707693;
          }
          .iconfont {
            width: 18px;
            font-size: 18px;
          }
        }
        .p2 {
          font-size: 12px;
          color: #2a3155;
        }
        &.tishi {
          .sp {
            width: 37px;
            height: 37px;
            display: inline-block;
            background: #415fd5;
            box-shadow: 0px 0px 4px 0px rgba(42, 49, 85, 0.2);
            border-radius: 40px;
            box-sizing: border-box;
            padding: 6px;
            .iconfont {
              color: #fff;
              font-size: 20px;
            }
            &.on {
              background: #f9c200;
            }
          }
        }
        &.on {
          color: #f9c200;
          .p2 {
            color: #9498aa;
          }
        }
      }
    }
  }
  .zongjie {
    padding: 16px;
    &-add {
      width: 343px;
      height: 40px;
      background: #f9c200;
      border-radius: 24px;
      margin-top: 15px;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }
    .title {
      height: 21px;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #2a3155;
      line-height: 21px;
      padding-bottom: 16px;
    }
    .weizhi {
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606580;
      line-height: 19px;
    }
    &-list {
      margin-bottom: 10px;
      position: relative;
      padding: 16px 26px;
      background: #eceffa;
      border-radius: 4px;
      .icon {
        position: absolute;
        top: 12px;
        left: -8px;
        padding: 5px;
        display: inline-block;
        background: #415fd5;
        box-shadow: 1px 1px 4px 0px rgba(42, 49, 85, 0.1);
        border-radius: 14px;
        line-height: 0;
        .img {
          width: 16px;
          height: 16px;
        }
      }
      .p1 {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2a3155;
        line-height: 19px;
        padding-bottom: 8px;
      }
      .p2 {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #9498aa;
        line-height: 19px;
      }
    }
    .p3 {
      padding-top: 23px;
      padding-bottom: 23px;
      text-align: center;
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #bfc1cc;
      line-height: 19px;
    }
    /deep/textarea::-webkit-input-placeholder {
      color: #666;
    }
    /deep/textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #666;
    }
    /deep/textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #666;
    }
    /deep/textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #666;
    }
  }
  .zj-msg {
    position: relative;
    .zjTs-prop {
      position: absolute;
      top: 50px;
      left: 10px;
      background: #fff;
      padding: 16px;
      width: 285px;
      box-shadow: 0px 0px 12px 0px rgba(42, 49, 85, 0.2);
      z-index: 999;
      .p1 {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2a3155;
        line-height: 19px;
      }
      li {
        background: #fef8e5;
        border-radius: 2px;
        margin: 8px 4px;
        line-height: 43px;
        padding-left: 16px;
      }
    }
  }
  .checkTopic-con {
    padding: 50px 20px;
    ul {
      // text-align: center;
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;
    }
    li {
      width: 17vw;
      height: 17vw;
      display: inline-block;
      background: rgb(15, 74, 201);
      color: #fff;
      margin: 10px;
      font-size: 18px;
      border-radius: 90px;
      text-align: center;
      line-height: 17vw;
    }
  }
}
.tishi-con {
  width: 264px;
  min-height: 22px;
  box-sizing: border-box;
  padding: 15px 12px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  .p1 {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #606580;
    line-height: 16px;
  }
  ul {
    margin-top: 8px;
    li {
      background: #ffffff;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      padding: 8px 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #2a3155;
      line-height: 16px;
      margin-bottom: 5px;
      &.on {
        background: url("./../../assets/img/bg_1.png");
        color: #ffffff;
        background-size: cover;
      }
    }
  }
}
.van-popup--center {
  border-radius: 16px;
  overflow: hidden;
}
.shouCangCon {
  position: relative;
  width: 327px;
  padding-bottom: 24px;
  .close-popup {
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 20px;
    color: #ffffff;
  }

  .top {
    height: 129px;
    background: url("./../../assets/img/sc_bj.png") center;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 52px;
      color: #fff;
    }
  }
  .info {
    .p1 {
      text-align: center;
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #606580;
      line-height: 21px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0 24px;
      justify-content: space-between;
      li {
        width: 132px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e6e8ef;

        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2a3155;
        line-height: 36px;
        text-align: center;
        margin-bottom: 12px;
        &.on {
          border: 1px solid #415fd5;
        }
      }
    }
    .zdy {
      width: 279px;
      margin: 0 auto;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e6e8ef;
      text-align: center;
      line-height: 36px;
      color: #bfc1cc;
      margin-bottom: 16px;
      &.on {
        border: 1px solid #415fd5;
      }
      input {
        border: none;
        width: 98%;
        padding-left: 10px;
        color: #000;
        box-sizing: border-box;
      }
      &-btn {
        width: 279px;
        height: 40px;
        background: #415fd5;
        border-radius: 24px;
        line-height: 40px;
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin: 0 auto;
      }
    }
  }
}

.directory-nav {
  position: fixed;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 2px 12px rgba(42, 49, 85, 0.15);
  writing-mode: tb-rl;
  left: -24px;
  bottom: 153px;
  background: white;

  > p {
    text-align: center;
    letter-spacing: 6px;
    padding: 6px 8px 0 0;
    color: #4461ce;
    font-weight: 500;
  }
}

.helpDialog {
  margin-top: 100px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__container {
    width: 320px;
    background: #ffffff;
    border-radius: 12px;
  }

  &__content {
    padding: 24px;

    > image {
      width: 272px;
      height: 272px;
      background: #e5e5e5;
      border-radius: 1px;
    }

    &-desc {
      height: 24px;
      font-family: PingFangSC-Regular;
      font-size: 17px;
      color: #7d8293;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      font-weight: 400;
      margin: 16px 0 32px;
    }
  }

  &__line {
    height: 1px;
    background: #e5e5e5;
  }

  &__close {
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 17px;
    color: #5377ff;
    letter-spacing: 2px;
    font-weight: 500;
  }
}


.nav-con {
  ul {
    margin-top: 50px;
    li {
      .nav-list-con {
        width: 248px;
        min-height: 51px;
        background: rgba(65, 95, 213, 0.1);
        border-radius: 12px;
        box-sizing: border-box;
        margin-left: 16px;
        margin-bottom: 12px;

        &.on {
          color: #fff;
          background: #415FD5;

          .jindu {
            width: 100%;
          }
        }

        .list-con {
          display: flex;
          flex-wrap: wrap;
          max-height: 135px;
          overflow: auto;

          span {
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 32px;
            margin: 10px;
            color: #fff;
            text-align: center;
            border: 1px solid #fff;

            &.on {
              color: #fff;
              background: #F9C200;
              border: 1px solid #F9C200;
            }

            &.err {
              color: #fff;
              background: red;
              border: 1px solid red;
            }

            &.succ {
              // color: #415FD5;
              background: #07c160;
              border: 1px solid #07c160;
            }
          }

          .special {
            width: 89px;
          }
        }
      }

      .jindu {
        // margin-top: 12px;
        width: 60px;
        height: 8px;
        background: #fff;
        border-radius: 5px;
        display: inline-block;
        // overflow: hidden;
        position: relative;

        .pair {
          background: #F9C200;
          position: absolute;
          border-radius: 5px;
          top: 0.5px;
          left: 0px;
          height: 8px;
        }
      }
    }
  }
}
</style>
